import map from 'lodash/map';
import { SearchResponse } from '@vue-storefront/core/types/search/SearchResponse'
import config from 'config'
import { slugify } from '@vue-storefront/core/helpers'
import { aggregationRemap } from 'theme/helpers/product';

export const decompactItem = (item, fieldsToCompact) => {
  for (const key in fieldsToCompact) {
    const value = fieldsToCompact[key]
    if (typeof item[value] !== 'undefined') {
      item[key] = item[value]
      delete item[value]
    }
  }
  return item
}

export const handleBaseResult = (resp, type, start = 0, size = 50): SearchResponse => {
  if (resp === null) {
    throw new Error('Invalid API result - null not exepcted')
  }
  if (resp.hasOwnProperty('hits')) {
    return {
      items: map(resp.hits, hit => {
        return Object.assign(hit, { slug: hit.slug ? hit.slug : ((hit.hasOwnProperty('url_key') && config.products.useMagentoUrlKeys) ? hit.url_key : (hit.hasOwnProperty('name') && hit.name !== null ? slugify(hit.name) + '-' + hit.id : '')) }) // TODO: assign slugs server side
      }), // TODO: add scoring information
      total: resp.total,
      start: start,
      perPage: size,
      aggregations: resp.aggregations,
      attributeMetadata: resp.attribute_metadata,
      suggestions: resp.suggest
    }
  } else {
    if (resp.error) {
      throw new Error(JSON.stringify(resp.error))
    } else {
      throw new Error('Unknown error with API catalog result in resultProcessor for entity type \'' + type + '\'')
    }
  }
}

export const handleProductResult = (resp, type, start = 0, size = 50): SearchResponse => {
  if (resp === null) {
    throw new Error('Invalid API result - null not exepcted')
  }
  if (resp.hasOwnProperty('hits')) {
    return {
      items: map(resp.hits, hit => {
        if (type === 'product') {
          hit = decompactItem(hit, config.products.fieldsToCompact)
          if (hit.configurable_children) {
            hit.configurable_children = hit.configurable_children.map(childItem => {
              return decompactItem(childItem, config.products.fieldsToCompact)
            })
          }
        }
        return Object.assign(hit, { slug: hit.slug ? hit.slug : ((hit.hasOwnProperty('url_key') && config.products.useMagentoUrlKeys) ? hit.url_key : (hit.hasOwnProperty('name') && hit.name !== null ? slugify(hit.name) + '-' + hit.id : '')) }) // TODO: assign slugs server side
      }), // TODO: add scoring information
      total: resp.total,
      start: start,
      perPage: size,
      aggregations: resp.aggregations,
      attributeMetadata: resp.attribute_metadata,
      suggestions: resp.suggest
    }
  } else {
    if (resp.error) {
      throw new Error(JSON.stringify(resp.error))
    } else {
      throw new Error('Unknown error with API catalog result in resultProcessor for entity type \'' + type + '\'')
    }
  }
}

export const handleProductV2Result = (resp, type, start = 0, size = 50): SearchResponse => {
  if (resp === null) {
    throw new Error('Invalid API result - null not exepcted')
  }
  if (resp.hasOwnProperty('hits')) {
    const aggregations = aggregationRemap(resp.aggregations)
    return {
      items: map(resp.hits, hit => {
        if (type === 'product_v2') {
          hit = decompactItem(hit, config.products.fieldsToCompact)
          if (hit.configurable_children) {
            hit.configurable_children = hit.configurable_children.map(childItem => {
              return decompactItem(childItem, config.products.fieldsToCompact)
            })
          }
        }
        return Object.assign(hit, { slug: hit.slug ? hit.slug : ((hit.hasOwnProperty('url_key') && config.products.useMagentoUrlKeys) ? hit.url_key : (hit.hasOwnProperty('name') && hit.name !== null ? slugify(hit.name) + '-' + hit.id : '')) }) // TODO: assign slugs server side
      }), // TODO: add scoring information
      total: resp.total,
      start: start,
      perPage: size,
      aggregations,
      attributeMetadata: resp.attribute_metadata,
      suggestions: resp.suggest
    }
  } else {
    if (resp.error) {
      throw new Error(JSON.stringify(resp.error))
    } else {
      throw new Error('Unknown error with API catalog result in resultProcessor for entity type \'' + type + '\'')
    }
  }
}

const handlers = {
  product: handleProductResult,
  product_v2: handleProductV2Result
}

export const getHandler = (resp, type, start = 0, size = 50): SearchResponse => {
  if (handlers[type]) {
    return handlers[type](resp, type, start, size)
  }

  return handleBaseResult(resp, type, start, size)
}
