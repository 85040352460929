import config from 'config';

export function getOtherStoreCode (storeCode: string) {
  let storeView = null
  if (storeCode && config.storeViews.multistore === true && config.storeViews[storeCode]) {
    storeView = config.storeViews[storeCode]
  }

  return storeView
}
